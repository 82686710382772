import axios from 'axios';

// const getBaseURL = () => {
//     switch (process.env.REACT_APP_ENV) {
//         case 'production':
//             return process.env.production.REACT_APP_ENV;
//         case 'vercel':
//             return process.env.vercel.REACT_APP_ENV;
//         default:
//             return 'http://localhost:3001';  // Fallback sur development
//     }
// };

// // Utiliser une variable d'environnement serait préférable
const baseURL = 'https://api-emprunt.ifr-reunion.re';

const instance = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        // Ajouter Accept pour être explicite sur le type de réponse attendu
        'Accept': 'application/json'
    }
});

// Amélioration de l'intercepteur
instance.interceptors.response.use(
    (response) => response,
    error => {
        // Destructuration plus claire
        const { response, request } = error;
        const status = response?.status;

        try {
            // Vérification plus robuste de l'URL
            const responseURL = request?.responseURL
                ? new URL(request.responseURL)
                : null;

            const isApiMeEndpoint = responseURL?.pathname === "/api/me";
            const isLoginPage = window.location.pathname === '/login';

            // Redirection uniquement si :
            // 1. Status est 401
            // 2. Ce n'est pas l'endpoint /api/me
            // 3. On n'est pas déjà sur la page de login
            if (status === 401 && !isApiMeEndpoint && !isLoginPage) {
                window.location.href = '/login';
            }
        } catch (urlError) {
            console.error('Erreur lors du parsing de l\'URL:', urlError);
        }

        return Promise.reject(error);
    }
);

const API_URL = '/api/';

export { instance, API_URL };