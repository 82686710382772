import Header from "components/Headers/Header";
import { Container, Spinner } from "reactstrap";


const Loading = () => {

    return (
        <>
            <Header />

            <Container className="text-center bg-gradient-info pb-9" fluid>
                <Spinner />
                <p className="display-6">Chargement en cours</p>
            </Container>
        </>
    )
}

export default Loading;