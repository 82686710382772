// Importer le module Axios configuré
import { instance, API_URL } from './axiosConfig';


// Fonction pour créer un formateur 
export async function createAFormateur(data) {
    try {
        const response = await instance.post(`${API_URL}formateur`, data);
        console.log(response.data);

        return response.data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour afficher un formateur
export async function getAFormateur(id) {
    try {
        const { data } = await instance.get(`${API_URL}formateur/${id}`);
        console.log(data);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour afficher tous les formateurs
export async function getAllApprenants() {
    try {
        const { data } = await instance.get(`${API_URL}apprenants`);
        return data;
    } catch (error) {
        console.error('Erreur lors de la récupération des apprenants:', error);
        throw error;  // Propagation de l'erreur pour meilleure gestion
    }
}

/**
 * Récupère tous les apprenants d'un groupe spécifique
 * @param {number|string} idGroupe - L'ID du groupe
 * @returns {Promise<Array>} Liste des apprenants du groupe
 * @throws {Error} Si la requête échoue
 */
export async function getAllApprenantsByGroupe(idGroupe) {
    try {
        if (!idGroupe) {
            throw new Error('ID du groupe requis');
        }

        const { data } = await instance.get(`${API_URL}apprenants/groupe/${idGroupe}`);
        return data;
    } catch (error) {
        if (error.response) {
            // L'erreur vient de l'API
            console.error('Erreur API:', error.response.data);
            throw new Error(error.response.data.message || 'Erreur lors de la récupération des apprenants');
        } else if (error.request) {
            // Pas de réponse de l'API
            console.error('Pas de réponse du serveur:', error.request);
            throw new Error('Le serveur ne répond pas');
        } else {
            // Autre erreur
            console.error('Erreur:', error.message);
            throw error;
        }
    }
}


// Fonction pour supprimer un formateur
export async function deleteAFormateur(id) {
    try {
        const response = await instance.delete(`${API_URL}formateur/${id}`);
        console.log("formateur supprimé :" + response.data);

        return response.data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour mettre à jour un formateur 
export async function updateAFormateur(id, data) {
    try {
        const response = await instance.patch(`${API_URL}formateur/${id}`, data);
        console.log(response.data);

        return response.data;
    } catch (error) {
        if (error.response) {

            // Erreur renvoyée par l'API avec un code de statut HTTP
            console.log('Erreur de mise à jour du formateur :', error.response.data);
        } else if (error.request) {

            // Aucune réponse reçue de l'API
            console.log('Pas de réponse de l\'API:', error.request);
        } else {
            
            // Erreur lors de la configuration de la requête ou autre erreur
            console.log('Erreur:', error.message);
        }
        throw new Error('Une erreur s\'est produite lors de la mise à jour du formateur.');
    }
}

// Fonction pour récupérer tous les formateurs par page 
export async function getAllFormateursPerPage(page, limit) {
    try {
        const { data } = await instance.get(`${API_URL}formateurs/page/${page}`, {
            params: {
                limit
            }
        });
        // console.log(data);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}