// layouts/Simple.js
import React, { useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";

import AdminFooter from "components/Footers/AdminFooter.js";

export const LayoutSimple = ({ children }) => {
    const mainContent = useRef(null);
    const location = useLocation();

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    return (
        <>
            <div className="wrapper">
                <div className="main-content" ref={mainContent}>
                    <div className="header bg-gradient-info py-7 py-lg-8">
                        <Container className="d-flex align-items-center">
                            <div>
                                <img 
                                    src={require("assets/img/brand/logo-ifr-fond-blanc.png")}
                                    alt="logo-ifr"
                                    style={{ height: "60px" }}
                                    className="mr-3"
                                />
                            </div>
                            <h1 className="text-white">Espace Emprunt</h1>
                        </Container>
                    </div>
                    
                    <Container className="mt--7">
                        {children}
                    </Container>

                    <Container fluid>
                        <AdminFooter />
                    </Container>
                </div>
            </div>
        </>
    );
};

export default LayoutSimple;