import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="py-5">
      <Container>
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-xl-left text-muted">
              © {currentYear}{" "}
              <a
                className="font-weight-bold ml-1"
                href="https://www.ifr-reunion.re/"
                target="_blank"
                rel="noopener noreferrer"
              >
                IFR - Institut de Formation de la Réunion
              </a>
            </div>
          </Col>

          <Col>
            <div className="text-xl-right text-muted">
              <a
                className="font-weight-bold ml-1"
                href="https://www.ifr-reunion.re/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contactez-nous
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;