// components/Modals/emprunts/CreateEmpruntQR.jsx
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';
import Select from 'react-select';
import SignatureCanvas from 'react-signature-canvas';
import { toast } from 'react-toastify';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  Alert
} from "reactstrap";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

// API
import { getAllFormateurs } from "api/Formateur";
import { getAllGroupeFormationsForLocation } from "api/GroupeFormation";
import { getAllApprenantsByGroupe } from "api/Apprenant";
import { createEmpruntQR } from 'api/EmpruntQR';
import { getAInventaire } from 'api/Inventaire';


const STEPS = {
  SELECT_FORMATEUR: 'SELECT_FORMATEUR',
  SELECT_GROUPE: 'SELECT_GROUPE',
  SELECT_APPRENANT: 'SELECT_APPRENANT',
  SCAN_PC: 'SCAN_PC',
  SIGNATURE: 'SIGNATURE'
};

const CreateEmpruntQR = ({ siteInfo }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const sigPad = useRef(null);

  const [modal, setModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(STEPS.SELECT_FORMATEUR);
  const [isScanning, setIsScanning] = useState(false);
  const [scanError, setScanError] = useState(null);

  // États pour les sélections
  const [selectedFormateur, setSelectedFormateur] = useState(null);
  const [selectedGroupe, setSelectedGroupe] = useState(null);
  const [selectedApprenant, setSelectedApprenant] = useState(null);
  const [scannedPC, setScannedPC] = useState(null);
  const [scannedPcId, setScannedPcId] = useState(null);

  // Requêtes
  const { data: formateurs, isLoading: isLoadingFormateurs } = useQuery({
    queryKey: ['formateurs'],
    queryFn: getAllFormateurs,
    enabled: modal
  });

  const { data: groupes, isLoading: isLoadingGroupes } = useQuery({
    queryKey: ['groupes', selectedFormateur?.value],
    queryFn: () => getAllGroupeFormationsForLocation(siteInfo.siteId),
    enabled: !!selectedFormateur
  });

  const { data: apprenants, isLoading: isLoadingApprenants } = useQuery({
    queryKey: ['apprenants', selectedGroupe?.value],
    queryFn: () => getAllApprenantsByGroupe(selectedGroupe.value),
    enabled: !!selectedGroupe
  });

  // Navigation entre les étapes
  const navigateToStep = (step) => {
    // Vérifier si la navigation est autorisée
    switch (step) {
      case STEPS.SELECT_GROUPE:
        if (!selectedFormateur) return;
        break;
      case STEPS.SELECT_APPRENANT:
        if (!selectedGroupe) return;
        break;
      case STEPS.SCAN_PC:
        if (!selectedApprenant) return;
        break;
      case STEPS.SIGNATURE:
        if (!scannedPcId) return;
        break;
      default:
        break;
    }
    setCurrentStep(step);
  };

  // Gestion des sélections avec navigation automatique
  const handleFormateurChange = (option) => {
    setSelectedFormateur(option);
    if (option) {
      setCurrentStep(STEPS.SELECT_GROUPE);
    }
  };

  const handleGroupeChange = (option) => {
    setSelectedGroupe(option);
    if (option) {
      setCurrentStep(STEPS.SELECT_APPRENANT);
    }
  };

  const handleApprenantChange = (option) => {
    setSelectedApprenant(option);
    if (option) {
      setCurrentStep(STEPS.SCAN_PC);
    }
  };

  // Mutation pour la création
  const { mutate: submitEmprunt, isPending: isSubmitting } = useMutation({
    mutationFn: createEmpruntQR,
    onSuccess: () => {
      queryClient.invalidateQueries('emprunts');
      toast.success("Emprunt QR créé avec succès");
      resetAndClose();

      // Redirection vers la page de login QR
      navigate('/qr/login');
    },
    onError: (error) => {
      console.error(error);
      toast.error(error.message || "Erreur lors de la création de l'emprunt QR");
    }
  });


  // Gestion du scan PC
  const handlePCScan = async (result) => {
    if (result?.text) {
      setIsScanning(true);
      setScanError(null);
      try {
        const pcData = JSON.parse(result.text);

        if (pcData.typeMateriel === "Ordinateur portable" && pcData.idInventaire) {
          // Vérifier la disponibilité dans la base de données
          const inventaireData = await getAInventaire(pcData.idInventaire);
          console.log(inventaireData);

          if (!inventaireData) {
            throw new Error("Ordinateur non trouvé dans l'inventaire");
          }

          setScannedPC({
            id: inventaireData.idInventaire,
            nomPc: inventaireData.nomPc || 'Non spécifiée',
            numeroSerie: inventaireData.numeroSerie || 'Non spécifié',
            disponible: inventaireData.disponible
          });
          setScannedPcId(inventaireData.idInventaire);

          // Si l'ordinateur est disponible, passer à l'étape signature
          if (inventaireData.disponible) {
            setCurrentStep(STEPS.SIGNATURE);
          } else {
            setScanError("Cet ordinateur n'est pas disponible actuellement. Veuillez en scanner un autre.");
          }
        } else {
          throw new Error("QR code invalide. Veuillez scanner un QR code d'ordinateur.");
        }
      } catch (error) {
        setScanError(error.message);
        toast.error(error.message);
      } finally {
        setIsScanning(false);
      }
    }
  };

  const handleSubmit = () => {
    if (sigPad.current.isEmpty()) {
      toast.error("Veuillez signer avant de valider");
      return;
    }

    const trimmedDataURL = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
    const filename = `signature_qr_${Date.now()}.png`;

    // Préparer les données pour l'emprunt QR
    const data = {
      idFormateur: selectedFormateur.value,
      idGroupeF: selectedGroupe.value,
      idApprenant: selectedApprenant.value,
      pcId: scannedPcId,
      siteId: siteInfo.siteId, // Utiliser le siteId du contexte QR
      signature: dataURLtoFile(trimmedDataURL, filename)
    };

    submitEmprunt(data);
  };


  // Reset et fermeture
  const resetAndClose = () => {
    setCurrentStep(STEPS.SELECT_FORMATEUR);
    setSelectedFormateur(null);
    setSelectedGroupe(null);
    setSelectedApprenant(null);
    setScannedPcId(null);
    if (sigPad.current) {
      sigPad.current.clear();
    }
    setModal(false);
  };

  const toggleModal = () => {
    if (modal) {
      resetAndClose();
    } else {
      setModal(true);
    }
  };

  // La fonction dataURLtoFile reste la même
  function dataURLtoFile(dataURL, filename) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  // Vérifier les informations du site
  if (!siteInfo || !siteInfo.siteId) {
    console.error('Information du site manquante');
    return null;
  }

  const PCInfoCard = () => {
    if (!scannedPC) return null;

    return (
      <div className="bg-dark p-3 rounded mb-3">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h6 className="text-white mb-0">Ordinateur sélectionné :</h6>
          <span className={`badge ${scannedPC.disponible ? 'bg-success' : 'bg-danger'} px-3 py-2`}>
            {scannedPC.disponible ? 'Disponible' : 'Non disponible'}
          </span>
        </div>
        <div className="text-white">
          <div><strong>ID :</strong> {scannedPC.id}</div>
          <div><strong>Nom PC :</strong> {scannedPC.nomPc}</div>
          <div><strong>Numéro de série :</strong> {scannedPC.numeroSerie}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Button color="primary" onClick={toggleModal}>
        <i className="fas fa-qrcode mr-2" />
        Emprunt QR Code - {siteInfo.siteName}
      </Button>

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="modal-dialog-centered"
        size="lg"
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>
          Nouvel emprunt par QR Code
        </ModalHeader>

        <ModalBody>
          {/* Barre de progression */}
          <Nav tabs className="mb-4">
            <NavItem>
              <NavLink
                className={currentStep === STEPS.SELECT_FORMATEUR ? 'active' : ''}
                onClick={() => navigateToStep(STEPS.SELECT_FORMATEUR)}
              >
                1. Formateur {selectedFormateur && '✓'}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={currentStep === STEPS.SELECT_GROUPE ? 'active' : ''}
                onClick={() => navigateToStep(STEPS.SELECT_GROUPE)}
                disabled={!selectedFormateur}
              >
                2. Groupe {selectedGroupe && '✓'}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={currentStep === STEPS.SELECT_APPRENANT ? 'active' : ''}
                onClick={() => navigateToStep(STEPS.SELECT_APPRENANT)}
                disabled={!selectedGroupe}
              >
                3. Apprenant {selectedApprenant && '✓'}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={currentStep === STEPS.SCAN_PC ? 'active' : ''}
                onClick={() => navigateToStep(STEPS.SCAN_PC)}
                disabled={!selectedApprenant}
              >
                4. Scanner PC {scannedPcId && '✓'}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={currentStep === STEPS.SIGNATURE ? 'active' : ''}
                onClick={() => navigateToStep(STEPS.SIGNATURE)}
                disabled={!scannedPcId}
              >
                5. Signature
              </NavLink>
            </NavItem>
          </Nav>

          {/* Contenu des étapes */}
          <div className="p-3">
            {currentStep === STEPS.SELECT_FORMATEUR && (
              <FormGroup>
                <Label>Sélectionnez le formateur :</Label>
                <Select
                  value={selectedFormateur}
                  onChange={handleFormateurChange}
                  options={formateurs?.map(f => ({
                    value: f.idFormateur,
                    label: f.user.fullName
                  }))}
                  isLoading={isLoadingFormateurs}
                  placeholder="Sélectionner un formateur"
                  className="form-control-alternative"
                />
              </FormGroup>
            )}

            {currentStep === STEPS.SELECT_GROUPE && (
              <FormGroup>
                <Label>Sélectionnez le groupe de formation :</Label>
                <Select
                  value={selectedGroupe}
                  onChange={handleGroupeChange}
                  options={groupes?.map(g => ({
                    value: g.idGroupeF,
                    label: g.nomSession + ' - ' + g.nomFormation
                  }))}
                  isLoading={isLoadingGroupes}
                  placeholder="Sélectionner un groupe"
                  className="form-control-alternative"
                />
              </FormGroup>
            )}

            {currentStep === STEPS.SELECT_APPRENANT && (
              <FormGroup>
                <Label>Sélectionnez l'apprenant :</Label>
                <Select
                  value={selectedApprenant}
                  onChange={handleApprenantChange}
                  options={apprenants?.map(a => ({
                    value: a.idApprenant,
                    label: a.nom + ' ' + a.prenom
                  }))}
                  isLoading={isLoadingApprenants}
                  placeholder="Sélectionner un apprenant"
                  className="form-control-alternative"
                />
              </FormGroup>
            )}

            {scannedPC && (currentStep === STEPS.SCAN_PC || currentStep === STEPS.SIGNATURE) && (
              <PCInfoCard />
            )}

            {currentStep === STEPS.SCAN_PC && (
              <div className="text-center">
                {scannedPC && <PCInfoCard />}

                {scanError && (
                  <Alert color="danger" className="mb-3">
                    {scanError}
                  </Alert>
                )}

                {isScanning ? (
                  <div className="text-center p-3">
                    <Spinner color="primary" size="sm" />
                    <div className="mt-1 small">Vérification en cours...</div>
                  </div>
                ) : (
                  <div className="qr-scanner-container">
                    <p className="small mb-2">
                      Veuillez scanner le QR code de l'ordinateur
                      {scanError && " disponible"}
                    </p>
                    <div style={{ maxWidth: '500px', margin: '0 auto' }}>
                      <QrReader
                        onResult={handlePCScan}
                        constraints={{ facingMode: 'environment' }}
                        containerStyle={{
                          marginBottom: '0.5rem',
                        }}
                        videoStyle={{
                          borderRadius: '0.375rem',
                          maxHeight: '500px'
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {currentStep === STEPS.SIGNATURE && (
              <div>
                <p>Veuillez signer pour valider l'emprunt :</p>
                <div className="signature-container">
                  <SignatureCanvas
                    ref={sigPad}
                    canvasProps={{
                      className: 'sigCanvas',
                      width: 500,
                      height: 200
                    }}
                  />
                  <hr />
                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary"
                      onClick={() => sigPad.current.clear()}
                    >
                      Effacer la signature
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ModalBody>

        <ModalFooter className="d-flex justify-content-between">
          <div>
            {currentStep !== STEPS.SELECT_FORMATEUR && (
              <Button
                color="secondary"
                onClick={() => {
                  switch (currentStep) {
                    case STEPS.SELECT_GROUPE:
                      setCurrentStep(STEPS.SELECT_FORMATEUR);
                      break;
                    case STEPS.SELECT_APPRENANT:
                      setCurrentStep(STEPS.SELECT_GROUPE);
                      break;
                    case STEPS.SCAN_PC:
                      setCurrentStep(STEPS.SELECT_APPRENANT);
                      break;
                    case STEPS.SIGNATURE:
                      setCurrentStep(STEPS.SCAN_PC);
                      break;
                    default:
                      break;
                  }
                }}
              >
                <i className="fas fa-arrow-left mr-2" />
                Retour
              </Button>
            )}
          </div>
          <div>
            <Button color="secondary" onClick={toggleModal} className="mr-2">
              Annuler
            </Button>
            {currentStep === STEPS.SIGNATURE && (
              <Button
                color="success"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <><Spinner color="light" size="sm" /> Enregistrement...</>
                ) : (
                  'Valider l\'emprunt'
                )}
              </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateEmpruntQR;