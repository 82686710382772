// Importer le module Axios configuré
import { instance, API_URL } from './axiosConfig';


// Fonction pour créer un ordinateur
export async function createOrdinateur(data) {
    try {
        const response = await instance.post(`${API_URL}ordinateur`, data);
        console.log(response.data);

        return response.data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour récupérer tous les ordinateurs
export async function getAllComputers() {
    try {
        const { data } = await instance.get(`${API_URL}ordinateurs`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour récupérer tous les ordinateurs par page
export async function getAllComputersPerPage(page, limit) {
    try {
        const { data } = await instance.get(`${API_URL}ordinateurs/${page}`, {
            params: {
                limit
            }
        });
        // console.log(data);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour récupérer tous les ordinateurs disponibles
export async function getAllComputersAavailables() {
    try {
        const { data } = await instance.get(`${API_URL}ordinateurs-available`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}


// Fonction pour récupérer tous les ordinateurs non disponibles
export async function getAllComputersNotAvailable() {
    try {
        const { data } = await instance.get(`${API_URL}ordinateurs-not-available`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour récupérer un seul ordinateur
export async function getComputerById(id) {
    try {
        const { data } = await instance.get(`${API_URL}ordinateur/${id}`);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour supprimer un ordinateur
export async function deleteComputer(id) {
    try {
        const response = await instance.delete(`${API_URL}ordinateur/${id}`);
        console.log("ordinateur supprimé :" + response.data);

        return response.data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction permettant de mettre à jour un ordinateur
export async function updateOneComputer(id, data) {
    try {
        const response = await instance.patch(`${API_URL}ordinateur/${id}`, data);
        console.log(response.data);

        return response.data;
    } catch (error) {
        if (error.response) {

            // Erreur renvoyée par l'API avec un code de statut HTTP
            console.log('Erreur de mise à jour de l\'ordinateur:', error.response.data);
        } else if (error.request) {

            // Aucune réponse reçue de l'API
            console.log('Pas de réponse de l\'API:', error.request);
        } else {

            // Erreur lors de la configuration de la requête ou autre erreur
            console.log('Erreur:', error.message);
        }
        throw new Error('Une erreur s\'est produite lors de la mise à jour de l\'ordinateur.');
    }
}


// Fonction permettant de mettre à jour la disponibilité d'un ordinateur : TRUE
export async function depositAllOrdinateurs(data) {
    try {
        const response = await instance.patch(`${API_URL}ordinateurs/not-available`, data);
        // console.log(response.data);

        return response.data;
    } catch (error) {
        if (error.response) {

            // Erreur renvoyée par l'API avec un code de statut HTTP
            console.log('Erreur de mise à jour de l\'ordinateur:', error.response.data);
        } else if (error.request) {

            // Aucune réponse reçue de l'API
            console.log('Pas de réponse de l\'API:', error.request);
        } else {

            // Erreur lors de la configuration de la requête ou autre erreur
            console.log('Erreur:', error.message);
        }
        throw new Error('Une erreur s\'est produite lors de la mise à jour de l\'ordinateur.');
    }
}


// Fonction permettant de mettre à jour la disponibilité d'un ordinateur : FALSE
export async function recoverAllOrdinateurs(data) {
    try {
        const response = await instance.patch(`${API_URL}ordinateurs/available`, data);
        // console.log(response.data);

        return response.data;
    } catch (error) {
        if (error.response) {

            // Erreur renvoyée par l'API avec un code de statut HTTP
            console.log('Erreur de mise à jour de l\'ordinateur:', error.response.data);
        } else if (error.request) {

            // Aucune réponse reçue de l'API
            console.log('Pas de réponse de l\'API:', error.request);
        } else {

            // Erreur lors de la configuration de la requête ou autre erreur
            console.log('Erreur:', error.message);
        }
        throw new Error('Une erreur s\'est produite lors de la mise à jour de l\'ordinateur.');
    }
}


// Fonction pour récupérer le nombre total d'ordinateurs
export async function getTotalComputerCount() {
    try {
        const computers = await getAllComputers();
        const totalCount = computers?.length;

        return totalCount;
    } catch (error) {
        console.log({ message: error.message });
        throw new Error('Une erreur s\'est produite lors de la récupération du nombre total d\'ordinateurs.');
    }
}


// -- DEPLACEMENT ORDINATEUR -- //

// Fonction pour déplacer plusieurs ordinateurs vers un autre lieu
export async function createMoveSelectedComputersToLocation(data) {
    try {
        // Effectuez une requête HTTP POST ou PUT pour envoyer les données au serveur
        const response = await instance.patch(`${API_URL}ordinateurs/move`, data);
        console.log(response.data);

        return response.data;
    } catch (error) {
        if (error.response) {
            // Erreur renvoyée par l'API avec un code de statut HTTP
            console.error('Erreur lors du déplacement des ordinateurs:', error.response.data);
        } else if (error.request) {
            // Aucune réponse reçue de l'API
            console.error('Pas de réponse de l\'API:', error.request);
        } else {
            // Erreur lors de la configuration de la requête ou autre erreur
            console.error('Erreur:', error.message);
        }
        throw new Error('Une erreur s\'est produite lors du déplacement des ordinateurs.');
    }
}


// -- LIEU -- // 

// Fonction pour obtenir le nombre total d'ordinateurs dans un lieu spécifique
export async function getTotalComputersByLieu(idLieu) {
    try {
        const { data } = await instance.get(`${API_URL}ordinateurs/count/${idLieu}`);
        return data.totalComputers;
    } catch (error) {
        console.log({ message: error.message });
        throw new Error('Une erreur s\'est produite lors de la récupération du nombre total d\'ordinateurs pour ce lieu.');
    }
}

// Fonction pour récupérer tous les ordinateurs disponibles pour un lieu spécifique
export async function getAllComputersAvailableForLocation(idLieu) {
    try {
        const { data } = await instance.get(`${API_URL}ordinateurs-available/${idLieu}`);
        return data;
    } catch (error) {
        console.log({ message: error.message });
        throw new Error('Une erreur s\'est produite lors de la récupération des ordinateurs disponibles pour ce lieu.');
    }
}

// Fonction pour récupérer l'ID et le nom du lieu de l'ordinateur
export async function getComputerLocationInfo(id) {
    try {
        const { data } = await instance.get(`${API_URL}ordinateur/location/${id}`);
        return data;
    } catch (error) {
        console.log({ message: error.message });
        throw new Error('Une erreur s\'est produite lors de la récupération des informations sur le lieu de l\'ordinateur.');
    }
}