// src/routes/index.js
import { qrRoutes } from './qrRoutes';
import { mainRoutes } from './mainRoutes';
import { createBrowserRouter } from 'react-router-dom';

// Views
// import PageError from "views/PageError";

// Création d'un router unifié
export const router = createBrowserRouter([

    // Routes principales (non-QR)
    {
        path: '/',
        children: mainRoutes
    },

    // Routes QR
    {
        path: '/qr',
        children: qrRoutes
    }
]);