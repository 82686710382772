import React from 'react';

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner
} from "reactstrap";

import { useLocation, useNavigation, Form, useActionData, useNavigate } from 'react-router-dom';

import LoginQRCode from 'components/Modals/qrcode/authentification/LoginQRCode';
import { useUser } from 'contexts/UserContext';

const Login = () => {
  const { logIn } = useUser();
  const actionData = useActionData();
  const navigation = useNavigation();
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const from = params.get("from") || "/";

  const isLoggingIn = navigation.formData?.get("email") && navigation.formData?.get("password") != null;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    
    try {
      await logIn({
        email: formData.get('email'),
        password: formData.get('password')
      });
      navigate('/');
    } catch (error) {
      // L'erreur sera gérée par le contexte utilisateur
    }
  };
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Connectez-vous avec vos identifiants</small>
            </div>

            <Form method="post" replace onSubmit={handleSubmit}>
              <input type="hidden" name="redirectTo" value={from} />

              {actionData && actionData.error ? (
                <p style={{ color: "red" }}>{actionData.error}</p>
              ) : null}

              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    autoComplete="on"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Mot de passe"
                    type="password"
                    name="password"
                    autoComplete="on"
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit" disabled={isLoggingIn}>
                  {isLoggingIn ? 

                  <Spinner size="sm">
                  Chargement ...
                </Spinner> : 
                
                "Se connecter"}
                </Button>

                {/* connexeion par QRCODE */}
                <LoginQRCode />

              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Mot de passe oublié ?</small>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;