import React from 'react';

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col, Spinner } from "reactstrap";

// API
import { getTotalComputerCount } from 'api/Ordinateur';
import { getTotalEmpruntCount, getTotalEmpruntReturnedCount, getAllEmpruntsActifsByIdUserCount, getAllEmpruntsReturnedByIdUserCount } from "api/Emprunt";

// CONTEXTE
import { useUser } from 'contexts/UserContext';
import { useQuery } from '@tanstack/react-query';

const Header = () => {
  const { userProfile, isLoggedOn } = useUser();

  const middlewareEmpruntCount = () => {
    if (!userProfile) {
      return;
    }

    if (userProfile.type !== "admin" && userProfile.type !== "equipe_pedagogique") {
      const empruntsDataCount = getAllEmpruntsActifsByIdUserCount(userProfile.idUser);
      return empruntsDataCount;
    } else {
      const empruntsDataCount = getTotalEmpruntCount();
      return empruntsDataCount;
    }
  }

  const middlewareEmpruntReturnedCountt = () => {
    if (!userProfile) {
      return;
    }

    if (userProfile.type !== "admin" && userProfile.type !== "equipe_pedagogique") {
      const empruntsDataCount = getAllEmpruntsReturnedByIdUserCount(userProfile.idUser);
      return empruntsDataCount;
    } else {
      const empruntsDataCount = getTotalEmpruntReturnedCount();
      return empruntsDataCount;
    }
  }

  const { data: computerCount, isLoading: isLoadingComputerCount, isError: isErrorComputerCount } = useQuery({
    queryKey: ["computerCount", isLoggedOn],
    queryFn: () => getTotalComputerCount(),
    enabled: true,
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
    networkMode: 'always',
    onError: (error) => {
      console.log('Erreur lors de la requête:', error);
    },
  });

  const { data: empruntCount, isLoading: isLoadingEmpruntCount, isError: isErrorEmpruntCount } = useQuery({
    queryKey: ["empruntCount", isLoggedOn],
    queryFn: () => middlewareEmpruntCount(),
    enabled: !!userProfile,
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
    networkMode: 'always',
    onError: (error) => {
      console.log('Erreur lors de la requête:', error);
    },
  });

  const { data: empruntRendusCount, isLoading: isLoadingEmpruntRendusCount, isError: isErrorEmpruntRendusCount } = useQuery({
    queryKey: ["empruntRendusCount", isLoggedOn],
    queryFn: () => middlewareEmpruntReturnedCountt(),
    enabled: !!userProfile,
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
    networkMode: 'always',
    onError: (error) => {
      console.log('Erreur lors de la requête:', error);
    },
  });

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">

            <Row>

              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total d'ordinateurs
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {isLoadingComputerCount ? (
                            <Spinner size="sm" />
                          ) : isErrorComputerCount ? (
                            <Spinner size="sm" color="danger" />
                          ) : (
                            computerCount
                          )}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                          <i className="ni ni-laptop" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Emprunts d'ordinateur actifs
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {isLoadingEmpruntCount ? (
                            <Spinner size="sm" />
                          ) : isErrorEmpruntCount ? (
                            <Spinner size="sm" color="danger" />
                          ) : (
                            empruntCount
                          )}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="ni ni-archive-2" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Emprunts d'ordinateur rendus
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {isLoadingEmpruntRendusCount ? (
                            <Spinner size="sm" />
                          ) : isErrorEmpruntRendusCount ? (
                            <Spinner size="sm" color="danger" />
                          ) : (
                            empruntRendusCount
                          )}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i className="fas fa-check-square" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;