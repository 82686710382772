// qrRoutes.js
import React from 'react';
import { LayoutAuth } from "layouts/Auth";
import { LayoutSimple } from "layouts/Simple";

// Views
import QREmprunt from "views/QREmprunt";
import LoginQRCode from 'components/Modals/qrcode/authentification/LoginQRCode';

export const qrRoutes = [
  {
    path: 'login',
    element: (
      <LayoutAuth>
        <LoginQRCode />
      </LayoutAuth>
    )
  },
  {
    path: 'emprunt',
    element: (
      <LayoutSimple>
          <QREmprunt />
       </LayoutSimple>
    )
  }
];