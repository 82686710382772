// Importer le module Axios configuré
import { instance, API_URL } from './axiosConfig';


// Fonction pour créer un formateur 
export async function createAFormateur(data) {
    try {
        const response = await instance.post(`${API_URL}formateur`, data);
        console.log(response.data);

        return response.data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour afficher un formateur
export async function getAFormateur(id) {
    try {
        const { data } = await instance.get(`${API_URL}formateur/${id}`);
        console.log(data);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour afficher tous les formateurs
export async function getAllFormateurs() {
    try {
        const { data } = await instance.get(`${API_URL}formateurs`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour supprimer un formateur
export async function deleteAFormateur(id) {
    try {
        const response = await instance.delete(`${API_URL}formateur/${id}`);
        console.log("formateur supprimé :" + response.data);

        return response.data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour mettre à jour un formateur 
export async function updateAFormateur(id, data) {
    try {
        const response = await instance.patch(`${API_URL}formateur/${id}`, data);
        console.log(response.data);

        return response.data;
    } catch (error) {
        if (error.response) {

            // Erreur renvoyée par l'API avec un code de statut HTTP
            console.log('Erreur de mise à jour du formateur :', error.response.data);
        } else if (error.request) {

            // Aucune réponse reçue de l'API
            console.log('Pas de réponse de l\'API:', error.request);
        } else {
            
            // Erreur lors de la configuration de la requête ou autre erreur
            console.log('Erreur:', error.message);
        }
        throw new Error('Une erreur s\'est produite lors de la mise à jour du formateur.');
    }
}

// Fonction pour récupérer le nombre total d'formateurs
export async function getTotalFormateurCount() {
    try {
        const computers = await getAllFormateurs();
        const totalCount = computers.length;
        return totalCount;
    } catch (error) {
        console.log({ message: error.message });
        throw new Error('Une erreur s\'est produite lors de la récupération du nombre total du formateurs.');
    }
}

// Fonction pour récupérer tous les formateurs par page 
export async function getAllFormateursPerPage(page, limit) {
    try {
        const { data } = await instance.get(`${API_URL}formateurs/page/${page}`, {
            params: {
                limit
            }
        });
        // console.log(data);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}