// Importer le module Axios configuré
import { instance, API_URL } from './axiosConfig';


// Fonction pour créer un lieu
export async function createLieu(data) {
    try {
        const response = await instance.post(`${API_URL}lieu`, data);
        console.log(response.data);

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

//Fonctions pour recuperer tous les lieux
export async function getAllLieux() {
    try {
        const { data } = await instance.get(`${API_URL}lieux`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log(error);
    }
}

// Fonction pour récupérer un lieu
export async function getLieuById(id) {
    try {
        const { data } = await instance.get(`${API_URL}lieu/${id}`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log(error);
    }
}

// Fonction pour mettre à jour un lieu 
export async function updateLieu(id, data) {
    try {
        const response = await instance.patch(`${API_URL}lieu/${id}`, data);
        console.log(response.data);

        return response.data;
    } catch (error) {
        if (error.response) {

            // Erreur renvoyée par l'API avec un code de statut HTTP
            console.log('Erreur de mise à jour du lieu:', error.response.data);
        } else if (error.request) {

            // Aucune réponse reçue de l'API
            console.log('Pas de réponse de l\'API:', error.request);
        } else {
            
            // Erreur lors de la configuration de la requête ou autre erreur
            console.log('Erreur:', error.message);
        }
        throw new Error('Une erreur s\'est produite lors de la mise à jour du lieu.');
    }
}

// Fonction pour supprimer un lieu 
export async function deleteLieu(id) {
    try {
        const { data } = await instance.delete(`${API_URL}lieu/${id}`);
        console.log(JSON.stringify(data));

        return data;
    } catch (error) {
        console.log(error);
    }
}