// Importer le module Axios configuré
import { instance, API_URL } from './axiosConfig';


// Fonction pour créer un inventaire
export async function createAInventaire(data) {
    try {
        const response = await instance.post(`${API_URL}inventaire`, data);
        console.log(response.data);

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

//Fonctions pour recuperer tous les inventaires
export async function getAllInventaires() {
    try {
        const { data } = await instance.get(`${API_URL}inventaires`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log(error);
    }
}

// Fonction pour récupérer tous les ordinateurs disponibles pour un lieu spécifique
export async function getAvailableComputersByLocation(emplacement) {
    try {
        const { data } = await instance.get(`${API_URL}inventaire/ordinateurs/emplacement/${emplacement}`);
        return data;
    } catch (error) {
        console.log({ message: error.message });
        throw new Error('Une erreur s\'est produite lors de la récupération des ordinateurs disponibles pour ce lieu.');
    }
}

// Fonction pour récupérer un inventaire
export async function getAInventaire(id) {
    try {
        const { data } = await instance.get(`${API_URL}inventaire/${id}`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log(error);
    }
}

// Fonction pour mettre à jour un inventaire 
export async function updateAInventaire(id, data) {
    try {
        const response = await instance.patch(`${API_URL}inventaire/${id}`, data);
        console.log(response.data);

        return response.data;
    } catch (error) {
        if (error.response) {

            // Erreur renvoyée par l'API avec un code de statut HTTP
            console.log('Erreur de mise à jour du inventaire:', error.response.data);
        } else if (error.request) {

            // Aucune réponse reçue de l'API
            console.log('Pas de réponse de l\'API:', error.request);
        } else {
            
            // Erreur lors de la configuration de la requête ou autre erreur
            console.log('Erreur:', error.message);
        }
        throw new Error('Une erreur s\'est produite lors de la mise à jour du inventaire.');
    }
}

// Fonction pour supprimer un inventaire 
export async function deleteAInventaire(id) {
    try {
        const { data } = await instance.delete(`${API_URL}inventaire/${id}`);
        console.log(JSON.stringify(data));

        return data;
    } catch (error) {
        console.log(error);
    }
}

// Fonction permettant de mettre à jour la disponibilité d'un ordinateur : TRUE
export async function updateAvailabilityForAllOrdinateurs(data) {
    // console.log("data:", data);
    try {
        const response = await instance.patch(`${API_URL}inventaire/ordinateurs/disponible`, data);

        return response.data;
    } catch (error) {
        if (error.response) {

            // Erreur renvoyée par l'API avec un code de statut HTTP
            console.log('Erreur de mise à jour de l\'ordinateur:', error.response.data);
        } else if (error.request) {

            // Aucune réponse reçue de l'API
            console.log('Pas de réponse de l\'API:', error.request);
        } else {

            // Erreur lors de la configuration de la requête ou autre erreur
            console.log('Erreur:', error.message);
        }
        throw new Error('Une erreur s\'est produite lors de la mise à jour de l\'ordinateur.');
    }
}
