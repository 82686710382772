// Importer le module Axios configuré
import { instance, API_URL } from './axiosConfig';

// Fonction pour ajouter un groupe de formation 
export async function createGroupeFormation(data) {
    try {
        const response = await instance.post(`${API_URL}groupe-formation`, data);
        console.log(response.data);

        return response.data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour afficher un groupe de formation
export async function getAGroupeFormation(id) {
    try {
        const { data } = await instance.get(`${API_URL}groupe-formation/${id}`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour afficher tous les groupe de formations
export async function getAllGroupeFormations() {
    try {
        const { data } = await instance.get(`${API_URL}groupes-formations`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}


// Fonction pour obtenir tous les groupes de formations par page
export async function getAllGroupesFormationsPerPage(page, limit) {
    try {
        const { data } = await instance.get(`${API_URL}groupes-formations/page/${page}`, {
            params: {
                limit
            }
        });
        // console.log("data :", data);
        return data;
    } catch (error) {
        console.log({ message: error.message });
        throw new Error('Une erreur s\'est produite lors de la récupération des groupes de formations par page.');
    }
}


// Fonction pour supprimer un groupe de formation
export async function deleteAGroupeFormation(id) {
    try {
        const response = await instance.delete(`${API_URL}groupe-formation/${id}`);
        console.log("Le groupe de formation a été supprimé :" + response.data);

        return response.data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

// Fonction pour mettre à jour un groupe de formation 
export async function updateAGroupeFormation(id, data) {
    try {
        const response = await instance.patch(`${API_URL}groupe-formation/${id}`, data);
        console.log(response.data);

        return response.data;
    } catch (error) {
        if (error.response) {

            // Erreur renvoyée par l'API avec un code de statut HTTP
            console.log('Erreur de mise à jour sur le groupe de formation :', error.response.data);
        } else if (error.request) {

            // Aucune réponse reçue de l'API
            console.log('Pas de réponse de l\'API:', error.request);
        } else {
            
            // Erreur lors de la configuration de la requête ou autre erreur
            console.log('Erreur:', error.message);
        }
        throw new Error('Une erreur s\'est produite lors de la mise à jour du groupe de formation');
    }
}


// Fonction pour récupérer tous les groupes de formations associés à un lieu
export async function getAllGroupeFormationsForLocation(idLieu) {
    try {
        const { data } = await instance.get(`${API_URL}groupes-formations/${idLieu}`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}