// components/Modals/auth/LoginQRCode.jsx
import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQR } from 'contexts/QRContext';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner
} from "reactstrap";

const LoginQRCode = () => {
  const [scannerModal, setScannerModal] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const { authenticateQR } = useQR();
  const navigate = useNavigate();

  const toggleScannerModal = () => {
    setScannerModal(!scannerModal);
    setIsScanning(false);
  };

  const handleScan = async (result) => {
    if (result?.text) {
      setIsScanning(true);
      try {
        const qrData = JSON.parse(result.text);

        // Authentification avec le QR code
        await authenticateQR(qrData);

        toggleScannerModal();

        // Redirection vers la page d'emprunt
        navigate('/qr/emprunt', { replace: true });

        toast.success('Connexion réussie');
      } catch (error) {
        console.error('Erreur lors du scan:', error);
        toast.error(error.message || 'Erreur lors de la connexion', {
          position: "top-right",
          autoClose: 3000,
          theme: "colored"
        });
      } finally {
        setIsScanning(false);
      }
    }
  };

  return (
    <div>
      <Button
        className="my-4 w-100"
        color="info"
        onClick={toggleScannerModal}
        disabled={isScanning}
      >
        <i className="fas fa-qrcode mr-2" />
        Lancer le scanne du QR Code
      </Button>

      <Modal
        className="modal-dialog-centered"
        isOpen={scannerModal}
        toggle={toggleScannerModal}
        backdrop="static"
      >
        <ModalHeader toggle={toggleScannerModal}>
          Scanner le QR Code du site IFR
        </ModalHeader>
        <ModalBody className="text-center">
          {isScanning ? (
            <div className="text-center p-4">
              <Spinner color="primary" />
              <div className="mt-2">Vérification en cours...</div>
            </div>
          ) : (
            <>
              <div className="mb-3">
                <i className="fas fa-info-circle text-info mr-2" />
                Scannez le QR code du site IFR où vous vous trouvez
              </div>
              <div style={{ maxWidth: '300px', margin: '0 auto' }}>
                <QrReader
                  onResult={handleScan}
                  constraints={{ facingMode: 'environment' }}
                  containerStyle={{ marginBottom: '1rem' }}
                  videoStyle={{
                    borderRadius: '0.375rem',
                    maxHeight: '300px'
                  }}
                />
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LoginQRCode;