import React, { useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";

import { Container, Row } from "reactstrap";

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";


export const LayoutAuth = ({ children }) => {

    const mainContent = useRef(null);
    const location = useLocation();

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    return (
        <>
            <div className="main-content" ref={mainContent}>
                <AuthNavbar />
                <div className="header bg-gradient-info py-7 py-lg-8"></div>
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        {children}
                    </Row>
                </Container>

            </div>
            <AuthFooter />
        </>
    );
};