// api/EmpruntQR.js
import { instance, API_URL } from './axiosConfig';

export const createEmpruntQR = async (formData) => {
  try {
    // Créer un FormData pour l'envoi du fichier et des données
    const form = new FormData();
    form.append('upload_file', formData.signature);
    form.append('idFormateur', formData.idFormateur);
    form.append('idGroupeF', formData.idGroupeF);
    form.append('idUser', formData.idApprenant);
    form.append('id_lieu_emprunt', formData.siteId);
    // Conversion en JSON string pour l'array d'ordinateurs
    form.append('ordinateurs', JSON.stringify([formData.pcId]));

    console.log(form);

    const response = await instance.post(`${API_URL}emprunt/qr/upload`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data;
  } catch (error) {
    throw error.response?.data || { error: 'Erreur lors de la création de l\'emprunt QR' };
  }
};