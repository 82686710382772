// api/AuthQR.js
import { instance, API_URL } from './axiosConfig';

// Authentification par QR Code
export const authenticateByQR = async (qrData) => {
  console.log(qrData);

  try {
    const response = await instance.post(`${API_URL}auth/qr`, { data: qrData});

    console.log(response);
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error.response?.data || { error: 'Erreur de connexion' };
  }
};