// contexts/QRContext.js
import React, { createContext, useContext, useCallback, useMemo, useState } from 'react';
import { authenticateByQR } from 'api/AuthQR';

const QRContext = createContext();

export const QRProvider = ({ children }) => {
    const [qrState, setQRState] = useState({
        qrSession: null,
        isLoading: false,
        error: null
    });

    const updateQRState = useCallback((updates) => {
        setQRState(prev => ({
            ...prev,
            ...updates
        }));
    }, []);

    const authenticateQR = useCallback(async (qrData) => {
        try {
            updateQRState({ isLoading: true, error: null });
            const response = await authenticateByQR(qrData);
            
            updateQRState({
                qrSession: response.guestSession,
                isLoading: false
            });

            return response;
        } catch (error) {
            updateQRState({
                error: error.message,
                isLoading: false
            });
            throw error;
        }
    }, [updateQRState]);

    const endQRSession = useCallback(() => {
        updateQRState({
            qrSession: null,
            error: null,
            isLoading: false
        });
    }, [updateQRState]);

    const contextValue = useMemo(() => ({
        ...qrState,
        isQRAuthenticated: !!qrState.qrSession,
        authenticateQR,
        endQRSession
    }), [qrState, authenticateQR, endQRSession]);

    return (
        <QRContext.Provider value={contextValue}>
            {children}
        </QRContext.Provider>
    );
};

export const useQR = () => {
    const context = useContext(QRContext);
    if (!context) {
        throw new Error('useQR must be used within a QRProvider');
    }
    return context;
};