import React from 'react';

// reactstrap components
import {
    Col,
    Input,
    Label
} from "reactstrap";

import { useLocation } from 'contexts/LocationContext';
import { getAllLieux } from 'api/Lieu';
import { useQuery } from '@tanstack/react-query';

// Composant ButtonChangeLocation pour gérer le changement de lieu
function ButtonChangeLocation() {
    // Utilisation du hook useLocation pour obtenir le lieu actuel et la fonction de mise à jour
    const { userLocation, updateLocation } = useLocation();

    // Fonction pour gérer le changement de lieu
    const handleSelectLieu = (e) => {
        const newLocation = e.target.value;

        // Mettez à jour le contexte avec le nouveau lieu
        updateLocation(newLocation);
    }

    const { data: lieux, isLoading, isError } = useQuery({
        queryKey: ["lieux"],
        queryFn: () => getAllLieux()
    });

    if (isLoading) return <div>Chargement des lieux...</div>;
    if (isError) return <div>Erreur de chargement des lieux</div>;

    return (
        <>
            <div className="row">
                <Label for="lieu" sm={2}>
                    <i className="ni ni-square-pin text-primary" />
                </Label>
                <Col sm={10}>
                    <Input name="lieu" id="lieu" type="select" onChange={handleSelectLieu} value={userLocation}>
                        <option value="*">Tout</option>
                        {lieux?.map((lieu, index) => (
                            <option key={index} value={lieu.idLieu}>{lieu.nomLieu}</option>
                        ))}
                    </Input>
                </Col>
            </div>
        </>
    );
}

export default ButtonChangeLocation;