import React from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import LoginQRCode from './Modals/qrcode/authentification/LoginQRCode';

const RescanQRPrompt = () => {

    return (
        <Container className="mt-5">
            <Card className="shadow border-0">
                <CardBody className="text-center p-5">
                    <div className="mb-4">
                        <i className="fas fa-qrcode fa-3x text-muted mb-3"></i>
                        <h2 className="mb-3">Session expirée</h2>
                        <p className="text-muted mb-4">
                            Votre session QR code n'est plus valide. Pour continuer, vous devez scanner à nouveau le QR code du site.
                        </p>
                    </div>
                    <LoginQRCode />
                </CardBody>
            </Card>
        </Container>
    );
};

export default RescanQRPrompt;