import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

import { useUser } from 'contexts/UserContext';

const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  // État local pour stocker le lieu de l'utilisateur
  const [userLocation, setUserLocation] = useState('');
  
  // Utilisation du hook de profil utilisateur pour obtenir les informations du profil
  const { userProfile } = useUser();

  // Fonction centralisée pour mettre à jour le lieu
  const updateUserLocation = (newLocation) => {
    setUserLocation(newLocation);

    // Stockage du lieu dans un cookie avec une expiration d'un an
    Cookies.set('userLocation', newLocation, { expires: 1, secure: true, sameSite: 'strict' });
  };

  // Ajout d'un effet pour récupérer la valeur du cookie lors de l'initialisation
  useEffect(() => {
    const storedLocation = Cookies.get('userLocation');
    if (storedLocation) {
      // Si le cookie existe, mettez à jour le lieu
      updateUserLocation(storedLocation);
    } else if (userProfile && userProfile.lieu) {
      // Sinon, utilisez le lieu du profil utilisateur s'il existe
      updateUserLocation(userProfile.lieu);
    }
  }, [userProfile]);

  // Fonction pour mettre à jour le lieu
  const updateLocation = (newLocation) => {
    updateUserLocation(newLocation);
  };

  // Fournit le contexte avec la valeur du lieu et la fonction de mise à jour
  return (
    <LocationContext.Provider value={{ userLocation, updateLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

// Hook personnalisé pour consommer le contexte de lieu
export const useLocation = () => {
  return useContext(LocationContext);
};