import React, { useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";

import { Container } from "reactstrap";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";

import Sidebar from "components/Sidebar/Sidebar.js";

export const LayoutAdmin = ({ children },) => {

    const mainContent = useRef(null);
    const location = useLocation();

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    return (
        <>
            <div className="wrapper">
                <Sidebar
                    logo={{
                        innerLink: "/",
                        imgSrc: require("assets/img/brand/logo-ifr-fond-blanc.png"),
                        imgAlt: "logo-ifr",
                        className: "logo-img"
                    }}
                />
                <div className="main-content" ref={mainContent}>

                    <AdminNavbar />
                    {children}
                    <Container fluid>
                        <AdminFooter />
                    </Container>
                </div>
            </div>
        </>
    );
};