// Importer le module Axios configuré
import { instance, API_URL } from './axiosConfig';

//Fonctions pour recuperer tous les emprunts
export async function getAllEmprunts() {
    try {
        const { data } = await instance.get(`${API_URL}emprunts`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log(error);
    }
}


// Fonction pour récupérer tous les emprunts par page
export async function getAllEmpruntsPerPage(page, limit) {
    try {
        const { data } = await instance.get(`${API_URL}emprunts/page/${page}`, {
            params: {
                limit
            }
        });
        // console.log(data);

        return data;
    } catch (error) {
        console.log({ message: error.message });
    }
}

//Fonctions pour recuperer tous les emprunts de l'user
export async function getAllEmpruntsByidUserPerPage(id, page, limit) {
    try {
        const { data } = await instance.get(`${API_URL}emprunts/user/${id}/page/${page}`, {
            params: {
                limit
            }
        });
        // console.log(data);

        return data;
    } catch (error) {
        console.log(error);
    }
}

//Fonctions pour recuperer tous les emprunts de l'user
export async function getAllEmpruntsByidUser(id) {
    try {
        const { data } = await instance.get(`${API_URL}emprunts/user/${id}`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log(error);
    }
}

//Fonctions pour recuperer tous les emprunts actifs
export async function getAllEmpruntsActif() {
    try {
        const { data } = await instance.get(`${API_URL}emprunts/active`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log(error);
    }
}

//Fonctions pour recuperer tous les emprunts actifs par user
export async function getAllEmpruntsActifsByIdUser(id) {
    try {
        const { data } = await instance.get(`${API_URL}emprunts/active/${id}`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log(error);
    }
}

//Fonctions pour recuperer tous les emprunts rendus
export async function getAllEmpruntsReturned() {
    try {
        const { data } = await instance.get(`${API_URL}emprunts/rendu`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log(error);
    }
}

//Fonctions pour recuperer tous les emprunts rendus
export async function getAllEmpruntsReturnedByIdUser(id) {
    try {
        const { data } = await instance.get(`${API_URL}emprunts/rendu/${id}`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log(error);
    }
}

// Fonction pour récupérer un emprunt
export async function getEmpruntById(id) {
    try {
        const { data } = await instance.get(`${API_URL}emprunt/${id}`);
        // console.log(data);

        return data;
    } catch (error) {
        console.log(error);
    }
}

// Fonction pour créer un emprunt
export async function createEmprunt(data) {
    try {
        const response = await instance.post(`${API_URL}emprunt/upload`, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

// Fonction pour mettre à jour un emprunt 
export async function updateEmprunt(id, data) {
    try {
        const response = await instance.patch(`${API_URL}emprunt/${id}`, data);

        return response.data;
    } catch (error) {
        if (error.response) {

            // Erreur renvoyée par l'API avec un code de statut HTTP
            console.log('Erreur de mise à jour de l\'emprunt:', error.response.data);
        } else if (error.request) {

            // Aucune réponse reçue de l'API
            console.log('Pas de réponse de l\'API:', error.request);
        } else {

            // Erreur lors de la configuration de la requête ou autre erreur
            console.log('Erreur:', error.message);
        }
        throw new Error('Une erreur s\'est produite lors de la mise à jour de l\'emprunt.');
    }
}

// Fonction pour supprimer un emprunt 
export async function deleteEmprunt(id) {
    try {
        const { data } = await instance.delete(`${API_URL}emprunt/${id}`);

        return data;
    } catch (error) {
        console.log(error);
    }
}

// Fonction pour deposer un emprunt 
// Fonction pour deposer un emprunt 
export async function depositEmpruntAndAddTime(id, data) {
    try {
        const formData = new FormData();
        formData.append('signatureRendu', data.signatureRendu);
        formData.append('dateHeureNow', data.dateHeureNow);
        formData.append('commentaire', data.commentaire);
        formData.append('idUserRendu', data.idUserRendu);
        formData.append('ordinateurs', JSON.stringify(data.ordinateurs));

        const response = await instance.patch(`${API_URL}emprunt/${id}/the-end`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return response.data;
    } catch (error) {
        if (error.response) {
            // Erreur renvoyée par l'API avec un code de statut HTTP
            console.log('Erreur de mise à jour de l\'emprunt:', error.response.data);
        } else if (error.request) {
            // Aucune réponse reçue de l'API
            console.log('Pas de réponse de l\'API:', error.request);
        } else {
            // Erreur lors de la configuration de la requête ou autre erreur
            console.log('Erreur:', error.message);
        }
        throw new Error('Une erreur s\'est produite lors de la mise à jour de l\'emprunt.');
    }
}



// ------- COUNT -------



// Fonction pour récupérer le nombre total de tous les emprunts
export async function getTotalEmpruntCount() {
    try {
        const emprunts = await getAllEmpruntsActif();
        const totalCount = emprunts?.length;

        return totalCount;
    } catch (error) {
        console.log({ message: error.message });
        throw new Error('Une erreur s\'est produite lors de la récupération du nombre total d\'emprunts.');
    }
}


// Fonction pour récupérer le nombre total des emprunts rendus
export async function getTotalEmpruntReturnedCount() {
    try {
        const empruntsRendus = await getAllEmpruntsReturned();
        const totalCount = empruntsRendus?.length;

        return totalCount;
    } catch (error) {
        console.log({ message: error.message });
        throw new Error('Une erreur s\'est produite lors de la récupération du nombre total d\'emprunts.');
    }
}

//Fonctions pour recuperer le nombre total de tous les emprunts actifs par user
export async function getAllEmpruntsActifsByIdUserCount(id) {
    try {
        const empruntActifByidUser = await getAllEmpruntsActifsByIdUser(id);
        const totalCountEmpruntActifByidUser = empruntActifByidUser?.length;

        return totalCountEmpruntActifByidUser;
    } catch (error) {
        console.log({ message: error.message });
        throw new Error('Une erreur s\'est produite lors de la récupération du nombre total d\'emprunts actifs.');
    }
}

//Fonctions pour recuperer le nombre total de tous les emprunts rendu par user
export async function getAllEmpruntsReturnedByIdUserCount(id) {
    try {
        const empruntReturnedByidUser = await getAllEmpruntsReturnedByIdUser(id);
        const totalCountEmpruntReturnedByidUser = empruntReturnedByidUser?.length;

        return totalCountEmpruntReturnedByidUser;
    } catch (error) {
        console.log({ message: error.message });
        throw new Error('Une erreur s\'est produite lors de la récupération du nombre total d\'emprunts rendus.');
    }
}