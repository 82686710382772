// views/QREmprunt.jsx
import React from 'react';
import { useQR } from 'contexts/QRContext';
import CreateEmpruntQR from 'components/Modals/qrcode/emprunt/CreateEmpruntQR';
import RescanQRPrompt from 'components/RescanQRPrompt';
import { Container, Card, CardBody, Spinner } from 'reactstrap';
import { redirect } from 'react-router-dom';

const QREmprunt = () => {
  const { qrSession, isLoading, error } = useQR();

  if (isLoading) {
    return (
      <Container className="mt-5 text-center">
        <Spinner color="primary" />
        <p>Chargement...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <div className="alert alert-danger">
          Une erreur est survenue : {error}
        </div>
        <RescanQRPrompt />
      </Container>
    );
  }

  if (!qrSession) {
    return <RescanQRPrompt />;
  }

  return (
    <Container className="mt-5">
      <Card className="shadow">
        <CardBody className="text-center">
          <h2 className="mb-4">Emprunt d'ordinateur</h2>
          <div className="alert alert-info mb-4">
            <h5 className="alert-heading text-white">Site actuel</h5>
            <p className="mb-0 text-white">{qrSession.siteName}</p>
          </div>
          <p className="text-muted">
            Suivez les étapes pour emprunter un ordinateur :
          </p>
          <ol className="text-left mb-4">
            <li>Sélectionnez votre formateur</li>
            <li>Choisissez votre groupe de formation</li>
            <li>Sélectionnez votre nom</li>
            <li>Scannez le QR code de l'ordinateur</li>
            <li>Signez pour valider l'emprunt</li>
          </ol>
          <CreateEmpruntQR siteInfo={qrSession} />
        </CardBody>
      </Card>
    </Container>
  );
};

export default QREmprunt;

// hooks/useQRAuth.js
export const useQRLoginAction = () => {
    const { authenticateQR } = useQR();

    const qrLoginAction = async ({ request }) => {
        try {
            const formData = await request.formData();
            const qrData = formData.get("qrData");

            if (!qrData) {
                return {
                    error: "Veuillez scanner le QR code du site pour continuer."
                };
            }

            const parsedQRData = JSON.parse(qrData);
            
            if (!parsedQRData.siteId || !parsedQRData.siteName) {
                return {
                    error: "Ce QR code n'est pas valide. Veuillez scanner le QR code du site."
                };
            }

            await authenticateQR(parsedQRData);
            return redirect("/qr/emprunt");

        } catch (error) {
            if (error.name === "SyntaxError") {
                return { 
                    error: "Format de QR code non reconnu. Veuillez réessayer."
                };
            }
            
            if (error.code === "ERR_NETWORK") {
                return { 
                    error: "Problème de connexion. Veuillez vérifier votre connexion et réessayer."
                };
            }

            return { 
                error: "QR code non valide. Veuillez scanner à nouveau le QR code du site." 
            };
        }
    };

    return qrLoginAction;
};