// mainRoutes.js
import React, { Suspense, lazy, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Layout 
import { LayoutAdmin } from "layouts/Admin";
import { LayoutAuth } from "layouts/Auth";

// Views 
import Loading from "views/Loading";
import Login from "views/Login";
import { useUser } from 'contexts/UserContext';


// Views avec lazy loading
const Dashboard = lazy(() => import("views/Index"));
const UserProfile = lazy(() => import("views/Profile"));
const Ordinateurs = lazy(() => import("views/examples/Ordinateurs"));
const Emprunt = lazy(() => import("views/examples/Emprunts"));
const Inventaire = lazy(() => import("views/Inventaire"));
const Lieux = lazy(() => import("views/Lieux"));
const Formateurs = lazy(() => import("views/examples/Formateurs"));
const GroupesFormation = lazy(() => import("views/examples/GroupesFormations"));

// Wrapper component pour l'authentification
const ProtectedRoute = ({ children }) => {
  const { isLoggedOn, isLoading } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isLoggedOn && isLoggedOn !== undefined) {
      navigate('/login');
    }
  }, [isLoading, isLoggedOn, navigate]);

  // Afficher le loader pendant la vérification initiale
  if (isLoading || isLoggedOn === undefined) {
    return <Loading />;
  }

  // Si pas connecté, afficher le loader en attendant la redirection
  if (!isLoggedOn) {
    return <Loading />;
  }

  return children;
};

// Wrapper pour la page de login
const LoginRoute = ({ children }) => {
  const { isLoggedOn, isLoading } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isLoggedOn && isLoggedOn !== undefined) {
      navigate('/');
    }
  }, [isLoading, isLoggedOn, navigate]);

  // Afficher le loader pendant la vérification initiale
  if (isLoading || isLoggedOn === undefined) {
    return <Loading />;
  }

  // Si connecté, afficher le loader en attendant la redirection
  if (isLoggedOn) {
    return <Loading />;
  }

  return children;
};

export const mainRoutes = [
  {
    index: true,
    element: (
      <ProtectedRoute>
        <LayoutAdmin>
          <Suspense fallback={<Loading />}>
            <Dashboard />
          </Suspense>
        </LayoutAdmin>
      </ProtectedRoute>
    )
  },
  {
    path: 'login',
    element: (
      <LoginRoute>
        <LayoutAuth>
          <Login />
        </LayoutAuth>
      </LoginRoute>
    )
  },
  {
    path: 'ordinateurs',
    element: (
      <ProtectedRoute>
        <LayoutAdmin>
          <Suspense fallback={<Loading />}>
            <Ordinateurs />
          </Suspense>
        </LayoutAdmin>
      </ProtectedRoute>
    )
  },
  {
    path: 'formateurs',
    element: (
      <ProtectedRoute>
        <LayoutAdmin>
          <Suspense fallback={<Loading />}>
            <Formateurs />
          </Suspense>
        </LayoutAdmin>
      </ProtectedRoute>
    )
  },
  {
    path: 'groupes-formation',
    element: (
      <ProtectedRoute>
        <LayoutAdmin>
          <Suspense fallback={<Loading />}>
            <GroupesFormation />
          </Suspense>
        </LayoutAdmin>
      </ProtectedRoute>
    )
  },
  {
    path: 'emprunts/ordinateurs',
    element: (
      <ProtectedRoute>
        <LayoutAdmin>
          <Suspense fallback={<Loading />}>
            <Emprunt />
          </Suspense>
        </LayoutAdmin>
      </ProtectedRoute>
    )
  },
  {
    path: 'lieux',
    element: (
      <ProtectedRoute>
        <LayoutAdmin>
          <Suspense fallback={<Loading />}>
            <Lieux />
          </Suspense>
        </LayoutAdmin>
      </ProtectedRoute>
    )
  },

  {
    path: 'inventaire',
    element: (
      <ProtectedRoute>
        <LayoutAdmin>
          <Suspense fallback={<Loading />}>
            <Inventaire />
          </Suspense>
        </LayoutAdmin>
      </ProtectedRoute>
    )
  },
  {
    path: 'user-profile',
    element: (
      <ProtectedRoute>
        <LayoutAdmin>
          <Suspense fallback={<Loading />}>
            <UserProfile />
          </Suspense>
        </LayoutAdmin>
      </ProtectedRoute>
    )
  },
  {
    path: 'user-profile/setting',
    element: (
      <ProtectedRoute>
        <LayoutAdmin>
          <Suspense fallback={<Loading />}>
            <p>Reglage de mon profil</p>
          </Suspense>
        </LayoutAdmin>
      </ProtectedRoute>
    )
  }
];