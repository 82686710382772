// contexts/UserContext.js
import React, { createContext, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { login, logout, auth, profileUserData } from 'api/Auth';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userState, setUserState] = useState({
        isLoggedOn: undefined,
        userProfile: null,
        type: null,
        isLoading: true, // Commence à true
        error: null
    });

    // Vérification de l'authentification
    useEffect(() => {
        const checkAuth = async () => {
            try {
                // On vérifie directement avec l'API
                await auth();
                const userData = await profileUserData();

                setUserState(prev => ({
                    ...prev,
                    isLoggedOn: true,
                    userProfile: userData,
                    type: userData.type,
                    isLoading: false
                }));
            } catch (error) {
                // Si l'appel échoue, l'utilisateur n'est pas authentifié
                setUserState(prev => ({
                    ...prev,
                    isLoggedOn: false,
                    userProfile: null,
                    type: null,
                    isLoading: false,
                    error: error.message
                }));
            }
        };

        checkAuth();
    }, []);


    const logIn = useCallback(async (formData) => {
        try {
            setUserState(prev => ({ ...prev, isLoading: true, error: null }));

            await login(formData);
            const userData = await profileUserData();

            setUserState(prev => ({
                ...prev,
                isLoggedOn: true,
                userProfile: userData,
                type: userData.type,
                isLoading: false
            }));
        } catch (error) {
            setUserState(prev => ({
                ...prev,
                error: error.message,
                isLoading: false
            }));
            throw error;
        }
    }, []);

    const logOut = useCallback(async () => {
        try {
            setUserState(prev => ({ ...prev, isLoading: true, error: null }));

            await logout();

            setUserState(prev => ({
                ...prev,
                isLoggedOn: false,
                userProfile: null,
                type: null,
                isLoading: false
            }));
        } catch (error) {
            setUserState(prev => ({
                ...prev,
                error: error.message,
                isLoading: false
            }));
            throw error;
        }
    }, []);

    const contextValue = useMemo(() => ({
        isLoggedOn: userState.isLoggedOn,
        userProfile: userState.userProfile,
        type: userState.type,
        isLoading: userState.isLoading,
        error: userState.error,
        logIn,
        logOut
    }), [
        userState.isLoggedOn,
        userState.userProfile,
        userState.type,
        userState.isLoading,
        userState.error,
        logIn,
        logOut
    ]);

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};